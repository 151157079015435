import React from 'react';
import classes from './PrivacyPolicyPage.module.scss';
import { WelcomeDefault } from '../../components/WelcomeDefault';
import WelcomeBackground from '../../assets/images/backgrounds/background-3.jpg';
import { privicy_policy } from '../../data';
import { SectionDefault } from '../../components/SectionDefault';

export const PrivacyPolicyPage = () => {

  console.log(privicy_policy)

  const theObjContent = {__html:privicy_policy[0]};

  return (
    <div>
      <WelcomeDefault
          title='Privacy policy'
          image={WelcomeBackground}
          shadow={false}
        />
        <SectionDefault>
          <div className={classes.content} dangerouslySetInnerHTML={theObjContent} />
        </SectionDefault>
    </div>
  );
};
