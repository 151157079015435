import React, { ReactNode } from 'react';
import classes from './TeamComposition.module.scss';

interface Props {
    items: any;
}

export const TeamComposition = ({items}: Props) => {
    

  return (
    <div className={classes.grid}>
        {
            items.map((item: string, index: number) => {
                return (
                    <div key={index} className={classes.cell}>
                        {item}
                    </div>   
                )
            })
        }
    </div>
  );
};
