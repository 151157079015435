import React from 'react';
import classes from './CardPrimary.module.scss';

interface Props {
    title: string;
    description: string;
    icon: string;
    background: string;
}

export const CardPrimary = ({title, description, icon, background}: Props) => {

  return (
    <div className={classes.card}  style={{ backgroundImage: `url(${background})` }}>
        <div className={classes.head}>
            <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }} />
        </div>
        <div className={classes.body}>
            <h5 className={classes.title}>{title}</h5>
            <div className={classes.description}>{description}</div>
        </div>
    </div>
  );
};
