import React, { ReactNode } from 'react';
import classes from './Grid.module.scss';

interface Props {
    columns: '2' | '3',
    children: ReactNode;
  }

export const Grid = ({ columns, children }:Props) => {
    const gridColumns = 'grid-'+columns;
  return (
    <div className={`${classes.grid} ${classes[gridColumns]}`}>
        {children}
    </div>
  );
};
