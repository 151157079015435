import React from 'react';
import classes from './CaseStudyPage.module.scss';
import { WelcomePrimary } from '../../components/WelcomePrimary';
import WelcomeBackground from '../../assets/images/backgrounds/background-2.jpg';
import WelcomePreview from '../../assets/images/cases/iot-frame-md.png';
import { caseIOT } from '../../data';
import { SectionContent } from '../../components/SectionContent';
import { SectionChallange } from '../../components/SectionChallange';
import { SectionContacts } from '../../components/SectionContacts';

export const CaseStudyPage = () => {

  return (
    <div>
      <WelcomePrimary
        title={caseIOT[0].title}
        description={caseIOT[0].description}
        image={WelcomeBackground}
        preview={WelcomePreview}
      />
      <SectionContent content={caseIOT[0].content} />
      <SectionChallange team={caseIOT[0].team} technologies={caseIOT[0].technologies} methodology={caseIOT[0].methodology}  />
      <SectionContacts />
    </div>
  );
};
