import React, { useState } from 'react';
import classes from './SectionContacts.module.scss';
import { SectionDefault } from '../SectionDefault';
import { ContactForm } from '../ContactForm';

export const SectionContacts = () => {
    const [thanks, setThanks] = useState(false);

    const updateData = (data: boolean) => {
        setThanks(data);
    };

  return (
    <SectionDefault>
        <div className={classes.grid} id='contacts'>
            <div className={classes.cell}>
                <h2 className={classes.title}>{!thanks ? 'Contact us' : 'Thank you!'}</h2>
                <div className={classes.description}>
                    <p>{!thanks ? 'Reach out to our experts for customized solutions that fit your business needs.' : "We've already got your message and are processing it. We do our best to contact you as soon as possible."}</p>
                </div>
            </div>
            <div className={classes.cell}>
                <ContactForm updateData={updateData} />
            </div>
        </div>
    </SectionDefault>
  );
};
