import React, { ReactNode, useEffect, useState } from 'react';
import classes from './ContactForm.module.scss';
import { useForm, SubmitHandler } from "react-hook-form";
import { ButtonPrimary } from '../ButtonPrimary';
import { Link } from 'react-router-dom';
import { PAGES } from '../../constants';
import axios from 'axios';

type Inputs = {
    name: string,
    email: string,
    phone: string,
    message: string
};

interface Props {
    updateData: (data: boolean) => void;
}

export const ContactForm = ({updateData}: Props) => {
    const [sentEmail, setSentEmail] = useState(false);

    useEffect(()=> {
        setTimeout(()=> {
            setSentEmail(false);
            updateData(false);
        },5000);
    },[sentEmail]);

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<Inputs>();

    const API_PATH = 'http://localhost/api/index.php';


    const onSubmit: SubmitHandler<Inputs> = (data, e:any) => {
        
        setSentEmail(true);
        updateData(true);

        // clear form
        e.target.reset();

        console.log(data, 'data update');

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: data
        })
        .then(result => {
            console.log(result);
        })
        .catch(error => console.log(error));
    };
    // console.log(watch("email"));
  return (
    <div className={classes.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.row}>
                <div className={classes.cell}>
                    <input
                        type="text"
                        placeholder='Name'
                        className={`${classes.control} ${errors.name ? classes.error : ''}`}
                        {...register("name", { required: true, validate: {
                            minLength: (v) => v.length >= 5,
                            matchPattern: (v) => /^[a-zA-Z0-9_]+$/.test(v),
                        }, })} 
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.cell}>
                    <input
                        type="text"
                        placeholder='Email'
                        className={`${classes.control} ${errors.email ? classes.error : ''}`}
                        {...register("email", { required: true, validate: {
                            maxLength: (v) =>
                            v.length <= 50,
                            matchPattern: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v),
                        }, })}/>
                </div>
                <div className={classes.cell}>
                    <input type="text" placeholder='Phone number (optional)' className={classes.control} {...register("phone")} />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.cell}>
                    <textarea className={classes.control} placeholder='Type your message' {...register("message")}></textarea>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.cell}>
                <div className={classes.checkbox}>
                    <label>
                        <input type="checkbox" />
                        <span>I agree to <Link to={PAGES.PRIVICY_POLICY}>Privacy Policy</Link> and allow processing my data to handle the request</span>
                    </label>
                </div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.cell}>
                    <ButtonPrimary type='submit' text='Send message' />
                </div>
            </div>
        </form>
        <div className={`${classes.thanks} ${sentEmail ? classes.show : ''}`} />
    </div>
  );
};