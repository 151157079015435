import React from 'react';
import classes from './Review.module.scss';

interface Props {
    logo: string;
    content: string;
    info: string;
}

export const Review = ({content, logo, info}: Props) => {

    const theObjContent = {__html:content};
    const theObjInfo = {__html:info};

  return (
    <div className={classes.review}>
        <div className={classes.head}>
            <div className={classes.logo} style={{ backgroundImage: `url(${logo})` }} />
            <div className={classes.info} dangerouslySetInnerHTML={theObjInfo} />
        </div>
        <div className={classes.body}>
            <div className={classes.content} dangerouslySetInnerHTML={theObjContent} />
        </div>
    </div>
  );
};
