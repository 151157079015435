import React from 'react';
import classes from './MobilePanel.module.scss';
import { selectModal } from '../../redux/modals/selectors';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { close, modals } from '../../redux/modals/slice';
import { ButtonDefault } from '../ButtonDefault';
import { MainMenu } from '../MainMenu';
import { Links } from '../Links';

export const MobilePanel = () => {
    const { modalMobilePanel: isShow } = useSelector(selectModal);
    const dispatch = useAppDispatch();

    // const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    //     e.preventDefault();
    //     dispatch(close(modals.modalMobilePanel));
    // };

    // React.useEffect(() => {
    //     if (isShow) {
    //         document.body.style.overflow = 'hidden';
    //     } else {
    //         document.body.style.overflow = '';
    //     }
    // }, [isShow]);

  return (
    <div className={`${classes.panel} ${isShow ? classes.open : ''} `}>
        <div className={classes.autoscroll}>
            <div className={'container'}>
                <div className={classes.body}>
                    <MainMenu />
                    <Links />
                </div>
                <div className={classes.footer}>
                    <ButtonDefault type={'ancher'} text={'Contact us'} />
                </div>
            </div>
        </div>
        {/* <button onClick={handleCloseClick}>test</button> */}
    </div>
  );
};
