import React, { useEffect, useState } from 'react';
import classes from './WelcomePrimary.module.scss';
import { useNavigate} from "react-router-dom";
import { Icons } from '../Svg';
import { useMediaQuery } from 'react-responsive';

interface Props {
    title: string;
    description?: string;
    image: string;
    preview: string;
  }

export const WelcomePrimary = ({title, description, image, preview}:Props) => {
  const isTable = useMediaQuery({ query: '(max-width: 1023px)' });
  
    const navigate = useNavigate()
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    },[]);

  return (
    <section className={`${classes.welcome} ${show ? classes.show : ''}`}>
        <div className={classes.inner}>
            <div className={'container'}>
                <div className={classes.panel}>
                  {isTable ? <div className={classes.float}>
                    <button className={classes.button} onClick={() => navigate(-1)}>
                      <Icons.IconBack />
                      Back
                    </button>
                  </div> : null }
                  <h1 className={classes.title}>{title}</h1>
                  {description ? (<div className={classes.description}>{description}</div>) : null}
                </div>
                <div className={classes.aside}>
                  <div className={classes.preview}>
                    <img src={preview} alt="" className={classes.cover} />
                  </div>
                </div>
            </div>
        </div>
        <div className={classes.media}>
            <div className={classes.image} style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    </section>
  );
};
