import React from 'react';
import classes from './CardCase.module.scss';
import { ButtonDefault } from '../ButtonDefault';
import { PAGES } from '../../constants';
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom';

interface Props {
    title: string;
    description: string;
    background: string;
    preview: string;
}

export const CardCase = ({title, description, background, preview}: Props) => {

    const isTablet = useMediaQuery({ query: '(max-width: 1023px)' });

  return (
        !isTablet ? (
            <div className={classes.card} style={{ backgroundImage: `url(${background})` }}>
                <div className={classes.media}>
                    <div className={classes.image} style={{ backgroundImage: `url(${preview})` }} />
                </div>
                <div className={classes.body}>
                    <div className={classes.head}>
                        <h3 className={classes.title}>{title}</h3>
                        <div className={classes.description}>{description}</div>
                    </div>
                    <div className={classes.footer}>
                        <ButtonDefault type={'link'} text={'See more'} link={PAGES.CASE_STUDY} />
                    </div>
                </div>

            </div>
        ) : (
            <Link to={PAGES.CASE_STUDY} className={classes.card} style={{ backgroundImage: `url(${background})` }}>
                <div className={classes.media}>
                    <div className={classes.image} style={{ backgroundImage: `url(${preview})` }} />
                </div>
                <div className={classes.body}>
                    <div className={classes.head}>
                        <h3 className={classes.title}>{title}</h3>
                        <div className={classes.description}>{description}</div>
                    </div>
                </div>
            </Link>
        )
  );
  
};
