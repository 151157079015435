import React from 'react';
import classes from './SectionChallange.module.scss';
import { Technologies } from '../Technologies';
import { TeamComposition } from '../TeamComposition';

interface Props {
    team?: any;
    technologies?: any;
    methodology: string;
}

export const SectionChallange = ({team, technologies, methodology}: Props) => {

    const theObjMethodology = {__html:methodology} ;

  return (
    <section className={classes.section}>
        <div className={'container'}>
            <div className={classes.list}>
                {methodology && <div className={classes.line}>
                    <h2 className={classes.title}>Methodology & Transparency</h2>
                    <div className={classes.content} dangerouslySetInnerHTML={theObjMethodology} />
                </div>}
                {team && <div className={classes.line}>
                    <h2 className={classes.title}>Team Composition</h2>
                    <TeamComposition items={team} />
                </div>}
                {technologies && <div className={classes.line}>
                    <h2 className={classes.title}>Technologies</h2>
                    <Technologies items={technologies} />
                </div>}
                
            </div>
            
        </div>
    </section>
  );
};
