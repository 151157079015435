
import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from '../../components/Footer';
import classes from './DefaultLayout.module.scss';
import React from "react";
import { MobilePanel } from "../../components/MobilePanel";

export default function DefaultLayout() {
  return (
    <div className={classes.layout}>
      <Header />
      <div className={classes.body}>
        <Outlet />
      </div>
      <MobilePanel />
      <Footer />
    </div>
  );
}