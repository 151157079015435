import React from 'react';
import classes from './Burger.module.scss';
import { useAppDispatch } from '../../redux/store';
import { modals, show, close } from '../../redux/modals/slice';
import { useSelector } from 'react-redux';
import { selectModal } from '../../redux/modals/selectors';

export const Burger = () => {
    const { modalMobilePanel: isShow } = useSelector(selectModal);
    const dispatch = useAppDispatch();
    
    return (
        <span className={`${classes.burger} ${isShow ? classes.open : ''}`}  onClick={() => isShow ? dispatch(close(modals.modalMobilePanel)) : dispatch(show(modals.modalMobilePanel))}>
            <span className={classes.line}></span>
        </span>
    );
};
