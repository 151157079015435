import React from 'react';
import classes from './CardDefault.module.scss';
import { useMediaQuery } from 'react-responsive';

interface Props {
    title: string;
    description: string;
    icon: string;
}

export const CardDefault = ({title, description, icon}: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <div className={classes.card}>
        {!isMobile ? <div className={classes.head}>
            <div className={classes.icon} style={{ backgroundImage: `url(${icon})` }} />
        </div> : null}
        <div className={classes.body}>
            <h4 className={classes.title}>{title}</h4>
            <div className={classes.description}>{description}</div>
        </div>
    </div>
  );
};
