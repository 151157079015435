import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import DefaultLayout from '../layouts/DefaultLayout';
import {  HomePage, NotFoundPage, CaseStudyPage, PrivacyPolicyPage } from '../pages';
import { PAGES } from '../constants';
import ScrollToTop from "../components/ScrollToTop";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index path={PAGES.HOME} element={<HomePage />} />
          <Route path={PAGES.CASE_STUDY} element={<CaseStudyPage />} />
          <Route path={PAGES.PRIVICY_POLICY} element={<PrivacyPolicyPage />} />
          <Route path={PAGES.PAGE_NOT_FOUND} element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to={PAGES.PAGE_NOT_FOUND} replace />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </BrowserRouter>
  );
};
