import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { MainMenu } from "../MainMenu";
import classes from './Header.module.scss';
import { ButtonDefault } from '../ButtonDefault';
import { Burger } from '../Burger';

export const Header = () => {
  const [headerTop, setHeaderTop] = useState(false);
  const isFixedMenu = headerTop ? classes.up : '';

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
      setHeaderTop(window.pageYOffset > 50)
      );
    }
  }, []);

  return (
    <header className={`${classes.header} ${isFixedMenu}`}>
      <div className={'container'}>
        <div className={classes.inner}>
          <div className={classes.panel}>
            <Link to={'/'} className={classes.logo} />
            <div className={classes.menu}>
              <MainMenu />
            </div>
          </div>
          <div className={classes.aside}>
            <ButtonDefault text={'Contact us'} link={'#contacts'} type={'ancher'} animation={true} />
          </div>
          <Burger />
        </div>
      </div>
    </header>
  );
};
