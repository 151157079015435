import React from 'react';
import { Link } from "react-router-dom";
import classes from './Footer.module.scss';
import { PAGES } from '../../constants';
import { Links } from '../Links';
import { useMediaQuery } from 'react-responsive';

export const Footer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <footer className={classes.footer}>
      <div className={classes.navigation}>
        <div className={'container'}>
          <div className={classes.grid}>
            <div className={classes.cell}>
              <Link to={'/'} className={classes.logo} />
            </div>
            {!isMobile ? <div className={classes.cell}>
              <ul className={classes.links}>
                <li className={classes.line}>
                  <Link to={PAGES.PRIVICY_POLICY}>Privacy Policy</Link>
                </li>
              </ul>
            </div> : null}
            <div className={classes.cell}>
              <Links />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.base}>
        <div className={'container'}>
          {isMobile ? <Link to={PAGES.PRIVICY_POLICY}>Privacy Policy</Link> : null }
          <p className={classes.copyright}>© {new Date().getFullYear()}, All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};
