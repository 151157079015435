import React from 'react';
import { NavLink } from 'react-router-dom';
import { MAIN_MENU } from '../../constants';
import classes from './MainMenu.module.scss';
import { useAppDispatch } from '../../redux/store';
import { modals, close } from '../../redux/modals/slice';
import { useSelector } from 'react-redux';
import { selectModal } from '../../redux/modals/selectors';

export const MainMenu = () => {

    const { modalMobilePanel: isShow } = useSelector(selectModal);
    const dispatch = useAppDispatch();

  return (
    MAIN_MENU ? (
        <ul className={classes.menu}>
            {MAIN_MENU.map((item, index) => {
                return (
                    <li key={index} className={classes.item}>
                        <NavLink onClick={() => isShow ? dispatch(close(modals.modalMobilePanel)) : null } to={item.link} className={({ isActive }) => isActive ? `${classes.active} ${classes.link}` : classes.link}>{item.name}</NavLink>
                    </li>
                    
                )
            })}
        </ul>
     ) : null
  );
};
