import React from 'react';
import classes from './ContentColumns.module.scss';

interface Content {
    title: string,
    description: string
}
interface Props {
    item: any;
}

export const ContentColumns = ({item}: Props) => {
  return (
    <div className={classes.grid}>
        {
            item.map((item: Content, index: number) => {
                return (
                    <div className={classes.box} key={index}>
                        <h4 className={classes.title}>{item.title}</h4>
                        <div className={classes.content}>{item.description}</div>
                    </div>
                )
            })
        }
    </div>
  );
};
