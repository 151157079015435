import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import classes from './ButtonDefault.module.scss';
import { modals, close } from '../../redux/modals/slice';
import { scrolls, scroll } from '../../redux/scroll/slice';
import { useSelector } from 'react-redux';
import { selectModal } from '../../redux/modals/selectors';
import { useAppDispatch } from '../../redux/store';

interface Props {
  text: string;
  link?: string;
  type: 'submit' | 'link' | 'ancher';
  animation?: boolean;
}

export const ButtonDefault = ({text, link = '', type, animation} : Props ) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modalMobilePanel: isShow } = useSelector(selectModal);

  const handleClickScroll = () => {
  
    const element = document.getElementById('contacts');
    isShow && dispatch(close(modals.modalMobilePanel));

    if (element) {

        element.scrollIntoView({ behavior: 'smooth' });
    } else {
      navigate('/');
      dispatch(scroll(scrolls.scroll));
    }
  };

  switch(type) {
      case 'submit':
          return <input type="submit" className={`${classes.button} ${animation ? classes.animation : ''}`} value={text} />
      case 'ancher':
          return <button className={`${classes.button} ${animation ? classes.animation : ''}`} onClick={handleClickScroll}>{text}</button>
      case 'link':
          return <Link to={link} className={`${classes.button} ${animation ? classes.animation : ''}`}>{text}</Link>
      default:
          return null;
  }

};