import { configureStore, Store } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import modalReducer from './modals/slice';
import scrollReducer from './scroll/slice';

export const store: Store = configureStore({
    reducer: { modal: modalReducer, scroll: scrollReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();