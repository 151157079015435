import React, { useEffect } from 'react';
import classes from './HomePage.module.scss';
import { WelcomeDefault } from '../../components/WelcomeDefault';
import WelcomeBackground from '../../assets/images/backgrounds/background-1.jpg';
import { SectionDefault } from '../../components/SectionDefault';
import { ContentCenter } from '../../components/ContentCenter';
import { reviews, challenges, benefits, business, digital } from '../../data';
import { Review } from '../../components/Review';
import { CardDefault } from '../../components/CardDefault';
import { Grid } from '../../components/Grid';
import { CardPrimary } from '../../components/CardPrimary';
import { ContentColumns } from '../../components/ContentColumns';
import { CardCase } from '../../components/CardCase';
import { Infographic } from '../../components/Infographic';
import { SectionContacts } from '../../components/SectionContacts';
import { useSelector } from 'react-redux';
import { selectScroll } from '../../redux/scroll/selectors';
import { reset, scrolls } from '../../redux/scroll/slice';
import { useAppDispatch } from '../../redux/store';

export const HomePage = () => {

  const { scroll: isShow } = useSelector(selectScroll);
  const dispatch = useAppDispatch();
    console.log(isShow);

  useEffect(() => {
    const element = document.getElementById('contacts');
    
    if (element && isShow) {
        element.scrollIntoView({ behavior: 'smooth' });
        dispatch(reset(scrolls.scroll));
        console.log(isShow);
    }
  }, []);

  return (
    <div>
        <WelcomeDefault
          title='Realize the potential of your business with digital twins'
          description='Experience the benefits of digital twins - real-time monitoring, simulation, and optimization of physical objects and systems.'
          image={WelcomeBackground}
        />
        <SectionDefault title='Challenges our clients face'>
          <Grid columns='3'>
            {
              challenges.map((item, index) => {
                return (
                  <CardDefault key={index} title={item.title} description={item.description} icon={item.icon} />
                )
              })
            }  
          </Grid>
        </SectionDefault>
        <SectionDefault type="primary">
          <ContentCenter>
            At XPG Factor, we understand the pain points of our clients and are committed to providing solutions that address their specific needs. Our digital twin technology enables real-time monitoring, control, and optimization of physical systems, reducing downtime, improving efficiency, and providing greater visibility and insights into operations. With XPG Factor, clients can optimize their systems' performance and make informed decisions to achieve their business goals.
          </ContentCenter>
        </SectionDefault>
        <SectionDefault title='Benefits of digital twins' description='Digital twins offer numerous benefits, including real-time monitoring, simulation, and optimization of physical objects and systems. They enable businesses to reduce downtime, improve efficiency, and make more informed decisions. With XPG Factor digital twin solutions, you can unlock these benefits and drive success for your business.'>
          <Grid columns='2'>
            {
              benefits.map((item, index) => {
                return (
                  <CardPrimary key={index} title={item.title} description={item.description} icon={item.icon} background={item.background} />
                )
              })
            }
          </Grid>
        </SectionDefault>
        <SectionDefault title='What clients say about us' description="Our clients' satisfaction is of utmost importance to us. Their feedback speaks volumes about our work. Look at what they say about their experience working with us and how our software development services helped them achieve their business objectives."  type="primary">
          <Review content={reviews[0].content} info={reviews[0].info} logo={reviews[0].logo} />
        </SectionDefault>
        <SectionDefault title='How digital twins work'>
          <Infographic />
        </SectionDefault>
        <SectionDefault title='Enhancing business operations with digital twins' type='primary'>
            <ContentColumns item={business} />
        </SectionDefault>
        <SectionDefault title='Digital twin solutions in action' description='Discover how businesses use digital twins to drive success in the manufacturing industry.' type='primary'>
          <CardCase description={digital[0].description} title={digital[0].title} background={digital[0].background} preview={digital[0].frame} />
        </SectionDefault>
        <SectionContacts />
    </div>
  );
};
function dispatch(arg0: { payload: string; type: "scrolls/reset"; }) {
  throw new Error('Function not implemented.');
}

