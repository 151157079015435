import React, { ReactNode } from 'react';
import classes from './ContentCenter.module.scss';

interface Props {
    children: ReactNode;
}

export const ContentCenter = ({children}: Props) => {

  return (
    <div className={classes.content}>
        {children}
    </div>
  );
};
