import React from 'react';
import { Link } from "react-router-dom";
import classes from './ButtonPrimary.module.scss';

interface Props {
  text: string;
  link?: string;
  type: 'submit' | 'link' | 'ancher';
}

export const ButtonPrimary = ({text, link = '', type} : Props ) => {

    switch(type) {
        case 'submit':
            return <input type="submit" className={classes.button} value={text} />
        case 'ancher':
            return <a href={link} className={classes.button}>{text}</a>
        case 'link':
            return <Link to={link} className={classes.button}>{text}</Link>
        default:
            return null;
    }

};
