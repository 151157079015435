import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
    modalMobilePanel: boolean;
}

export enum modals {
    modalMobilePanel = 'modalMobilePanel',
}

const initialState: ModalState = {
    modalMobilePanel: false,
};

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        show: (state, action: PayloadAction<string>) => {
            state[action.payload as keyof typeof initialState] = true;
        },
        close: (state, action: PayloadAction<string>) => {
            state[action.payload as keyof typeof initialState] = false;
        },
    },
});

export const { show, close } = modalSlice.actions;

export default modalSlice.reducer;
