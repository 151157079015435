import React, { useState } from 'react';
import classes from './Infographic.module.scss';

export const Infographic = () => {
    const [position, setPosition] = useState(1);

    const currentPositon = 'step-'+position;

  return (
    <div className={classes.infographic}>
        <div className={`${classes.progressbar} ${classes[currentPositon]}`}></div>
        <div className={classes.grid}>
            <div
                className={classes.box} 
                onMouseEnter={() => setPosition(1)}
                onMouseLeave={() => setPosition(1)}
            >
                <span className={classes.point}></span>
                <strong className={classes.title}>Data collection</strong>
                <div className={classes.description}>
                    <p>In the data collection step, real-time data is gathered from sensors and other sources to provide information about the physical object or system.</p>
                </div>
            </div>
            <div
                className={classes.box} 
                onMouseEnter={() => setPosition(2)}
                onMouseLeave={() => setPosition(1)}
            >
                <span className={classes.point}></span>
                <strong className={classes.title}>Analysis</strong>
                <div className={classes.description}>
                    <p>During analysis, collected data is processed to identify patterns, trends, and potential issues. This is crucial for optimizing performance and reducing maintenance costs by identifying issues before they occur.</p>
                </div>
            </div>
            <div
                className={classes.box} 
                onMouseEnter={() => setPosition(3)}
                onMouseLeave={() => setPosition(1)}
            >
                <span className={classes.point}></span>
                <strong className={classes.title}>Model creation</strong>
                <div className={classes.description}>
                    <p>Use machine learning, AI, and other technologies to create a digital twin. It enables real-time monitoring, control, simulation, and optimization.</p>
                </div>
            </div>
        </div>
    </div>
  );
};
