import LogoSMSGroup from './assets/images/logos/sms-group.png';
import IconEfficiency from './assets/images/icons/efficiency.svg';
import IconOptimization from './assets/images/icons/optimization.svg';
import IconSketching from './assets/images/icons/sketching.svg';
import IconDataAnalytics from './assets/images/icons/data-analytics.svg';
import Icon3dModeling from './assets/images/icons/3d-modeling.svg';
import IconLaunch from './assets/images/icons/launch.svg';
import IconSpanner from './assets/images/icons/spanner.svg';
import IconOPC from './assets/images/icons/opc.svg';
import IconRabbitMQ from  './assets/images/icons/rabbitmq.svg';
import IconNodeJS from  './assets/images/icons/nodejs.svg';
import IconThingsboard from  './assets/images/icons/thingsboard.svg';
import IconReactJS from  './assets/images/icons/reactjs.svg';

import BackgroundMonitoring from './assets/images/services/our-services-1.jpg';
import BackgroundSimulation from './assets/images/services/our-services-2.jpg';
import BackgroundOptimization from './assets/images/services/our-services-3.jpg';
import BackgroundMaintenance from './assets/images/services/our-services-4.jpg';

import BackgroundCaseIOT from './assets/images/cases/сase-iot.jpg';
import FrameIOT from './assets/images/cases/iot-frame.png';
import ContentIOT from './assets/images/cases/iot.jpg';

import { PAGES } from './constants';

export const reviews = [
    {
      id: 1,
      logo: LogoSMSGroup,
      content: '<p>SMS group stands for future-oriented technology and outstanding service in plant construction and mechanical engineering for the metals industry. We are working with lots of companies all over the world. One of them is the software company XPG Factor. XPG Factor team managed to smoothly and fast enter to our processes. With their help, we reduced software maintenance costs and work hours for its support. Being flexible the team did their best to meet all our requirements, adjustments and accomplished the set of technological goals. XPG Factor proved to be a reliable and professional partner.</p> <p>We would like to thank XPG Factor for their purposeful and flexible approach. We would definitely recommend them as software partner.</p>',
      info: '<p>Dr.-Ing. Wilfried Klos</p><p>Head of Melting and Casting Technology</p><p>Research & Development</p>',
    },
];

export const challenges = [
    {
        id: 1,
        icon: IconEfficiency,
        title: 'Downtime and inefficiencies',
        description: 'Clients may experience production downtime or inefficiencies in their physical systems, resulting in losses and decreased productivity.'
    },
    {
        id: 2,
        icon: IconSketching,
        title: 'Limited control and visibility',
        description: 'Clients may lack control and visibility into their physical systems, making it difficult to identify issues or make informed decisions.'
    },
    {
        id: 3,
        icon: IconOptimization,
        title: 'Inability to optimize performance',
        description: "Limited data and insights hinder clients' system performance optimization, leading to missed opportunities and increased costs."
    }
];

export const benefits = [
    {
        id: 1,
        icon: IconDataAnalytics,
        title: 'Real-time monitoring',
        description: 'Monitor physical objects and systems in real-time to identify issues, prevent downtime, and optimize performance.',
        background: BackgroundMonitoring
    },
    {
        id: 2,
        icon: Icon3dModeling,
        title: 'Simulation',
        description: 'Simulate scenarios to identify potential problems, optimize performance, and reduce risk.',
        background: BackgroundSimulation
    },
    {
        id: 3,
        icon: IconLaunch,
        title: 'Optimization',
        description: 'Optimize performance and reduce costs by analyzing real-time data and making informed decisions.',
        background: BackgroundOptimization
    },
    {
        id: 4,
        icon: IconSpanner,
        title: 'Predictive Maintenance',
        description: 'Prevent equipment failure by analyzing real-time data, identifying patterns, and scheduling proactive maintenance.',
        background: BackgroundMaintenance
    },
];

export const business = [
    {
        id: 1,
        title: 'Improved operational efficiency',
        description: 'Digital twins can help businesses optimize their physical assets and systems, improving operational efficiency. By creating a virtual replica of their physical assets, companies can monitor their real-time performance and identify improvement areas. This enables businesses to reduce downtime, improve maintenance processes, and ultimately increase productivity and profitability.'
    },
    {
        id: 2,
        title: 'Increased business agility',
        description: 'Digital twins can help businesses become more agile and responsive to changing market conditions. By using digital twins to simulate and test different scenarios, companies can identify potential issues and opportunities before they occur. This enables them to make informed decisions and adapt to changing circumstances more effectively. In addition, digital twins can provide businesses with valuable data insights that can inform strategic planning and decision-making.',
    }
];

export const digital = [
    {
        id: 1,
        title: 'IoT software for data visualization and work with digital twins plants',
        description: 'Digital twins of steel production plants with dashboards for data analysis and monitoring.',
        background: BackgroundCaseIOT,
        frame: FrameIOT,
    }
];


export const caseIOT = [
    {
        title: 'IoT software for data visualization and work with digital twins plants',
        description: 'Digital twins of steel production plants with dashboards for data analysis and monitoring',
        content: `
            <h3>About the client</h3>
            <p>The client is one of the leading industrial companies in Europe headquartered in Germany. The company's primary focus is on the steel production industry.</p>
            <h3>Objective</h3>
            <p>The company approached XPG factor with a request to create a software platform to monitor and analyze process data in real time for their continuous casting machines.</p>
            <h3>Challenge</h3>
            <p>The customer had outdated software tailored to a specific plants. The physical presence of assistants/managers was required at the plant to track the production process, which complicated the process.</p>
            <h3>Solution</h3>
            <p>The XPG factor team suggested moving all the data online to solve the client's problem. </p>
            <h4>Data storage</h4>
            <p>By the client’s request the XPG factor team set up the data flow through the cloud infrastructure/environment. This made the approach totally  different from the typical use of third-party or self-written services. The solution resulted in faster tasks’ completion, centralized data storage without the need for additional programs, and quicker customization and development. Plants’ digital twins also help to track data at the stage of smelting in real time.</p>
            <h4>Visualization</h4>
            <p>The XPG factor team used the Thingsboard IoT framework to visualize the client's data. The framework provides cross-device connection, signal visualization, and customizable dashboard creation tailored to the client's needs. As part of the project, we created multiple dashboards that display the current state of the production line, with integrated digital twins of the plants for easy access. Experts can remotely monitor the steel casting machine's operation through the system.</p>
            <h4>Security</h4>
            <p>The team provided security on the project with the help of multilayer user authentication.</p>
            <h3>Key Features</h3>
            <ul>
                <li>Real-time data analytics</li>
                <li>Customizable alarm dispatching</li>
                <li>Measuring results verification</li>
                <li>Comparative analysis of production and maintenance data</li>
                <li>Trends analytics</li>
                <li>Customizable rules engine</li>
            </ul>
            <figure>
                <img src=${ContentIOT} alt="">
            </figure>
        `,
        team: ['Frontend developer', '2 backend developers', 'Cloud engineer'],
        technologies: [
            {
                title: 'Frontend',
                list: [
                    {
                        id: 1,
                        title: 'React.JS',
                        icon: IconReactJS
                    },
                    {
                        id: 2,
                        title: 'Thingsboard',
                        icon: IconThingsboard
                    }
                ]
            },
            {
                title: 'Backend',
                list: [
                    {
                        id: 1,
                        title: 'Node.js',
                        icon: IconNodeJS
                    },
                    {
                        id: 2,
                        title: 'RabbitMQ',
                        icon: IconRabbitMQ
                    }
                ]
            },
            {
                title: 'Other',
                list: [
                    {
                        id: 1,
                        title: 'OPC UA',
                        icon: IconOPC
                    },
                ]
            }
        ],
        methodology: `
            <p>During the project, the XPG factor team followed the Agile methodology. During weekly online meetings with the customer team leader, we shared reports on completed tasks and settled  project management issues for the client.</p>
            <p>To keep the client informed of their progress, the team also conducted weekly demos to showcase the results.</p>
        `,
    }
];

export const privicy_policy = [
    `
    <h4>WHO WE ARE</h4>
    <p>This privacy policy sets out how XPG factor sp. z o.o. located at Poland, 02-001 Warsaw, Aleje Jerozolimskie 89-43, use and protect any information that you give us when you visit our websites, visit our blog pages and subscribe to our marketing information.</p>
    <p>XPG factor sp. z o.o. is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used for the purpose it was collected for, in accordance with this privacy statement.</p>
    <p>XPG factor sp. z o.o. may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 24.09.2022.</p>
    <h4>WHAT WE COLLECT</h4>
    <p>Google Analytics tracking is installed to gather other information relevant to customer journey like device preference, age range, geographical location etc.</p>
    <p>Contact form is added to the website in order to receive customers’ requests and inquiries. We collect email and First Name to give feedback to customers.</p>
    <p>Drift chat that is used for instant communication with potentials customers. Drift chat gathers the following information: device preference, geographical location, IP address and may ask a customer to leave his/her email address.</p>
    <h4>WHAT WE DO WITH THE INFORMATION WE GATHER</h4>
    <p>We require this information to understand your needs and provide you with a better service. We may use the information to improve our products and services. We may use the information to customise the website according to your interests.</p>
    <p>If you voluntarily give us your contact information we may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided from time to time, we may also use your information to contact you for market research purposes. We may contact you by email.</p>
    <p>XPG factor sp. z o.o. guarantees they will do everything to ensure security of the personal data of users visiting this website. You can always unsubscribe, edit or delete your personal data in our database. You can find more information on this in the next paragraph.</p>
    <h4>CONTROLLING YOUR PERSONAL INFORMATION</h4>
    <p>You may request details of personal information which we hold about you. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by:</p>
    <p><strong>Writing to us at:</strong><br>XPG factor sp. z o.o.<br>02-001<br>Poland, Warsaw,<br>Aleje Jerozolimskie 89-43<br><strong>Emailing us at:&nbsp;</strong><a href="mailto:contact@xpgraph.com">contact@xpgraph.com</a><br><strong>Calling us on:</strong>&nbsp;+48 883 632 340</p>
    <p>We will not sell, distribute or lease your personal information to third parties unless we have your permission, or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting, if you tell us that you wish this to happen.</p>
    <p>If you believe that any information we hold on you is incorrect or incomplete, please contact us in one of the listed above ways as soon as possible. We will promptly correct any information found to be incorrect.</p>
    <h4>SECURITY</h4>
    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
    <h4>WHAT ARE COOKIES AND HOW DO THEY WORK?</h4>
    <p>Cookies are small text files that are sent to, and stored on, your computer, smartphone or other device for accessing the internet, whenever you visit a website. Cookies are useful because they allow us to tailor the website’s user experience to you (for example, by remembering previous preferences you have set).</p>
    <p>Once you agree, the file is added and the cookie helps analyse web traffic or lets us know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
    <p>This policy refers to ‘cookies’ throughout. However, it also includes these similar technologies. These include Flash cookies or Local Shared Objects, also known as LSOs. Read more about them at&nbsp;<a href="http://www.allaboutcookies.org/privacy-concerns/" target="_blank" rel="noreferrer noopener">http://www.allaboutcookies.org/privacy-concerns/</a>.</p>
    <h4>HOW WE USE COOKIES</h4>
    <p>We use cookies on our site to provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us (for example via the enquiry form). Please note that our cookies do not store your personal information, such as your name, address, telephone or email.</p>
    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
    <h3>COOKIES SET BY THIRD-PARTY SERVICES INSTALLED ON THIS SITE</h3>
    <ul>
        <li>_ga – Google Analytics cookie. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.</li>
        <li>_gat – Google Analytics cookie. It is used to throttle the request rate – limiting the collection of data on high traffic sites. It expires after 10 minutes.</li>
        <li>_gid – Google Analytics cookie. It is used to throttle the request rate – limiting the collection of data on high traffic sites. It expires after 24 hours.</li>
    </ul>
    <p>XPG factor sp. z o.o. website uses ‘analytics’ cookies to help us make the website better for those who visit it regularly. They help us work out what users like and don’t like, as well as ways we can improve the website and its content.</p>
    <p>It is possible to opt out of having your anonymised browsing activity within websites recorded by analytics cookies. XPG factor sp. z o.o. uses Google Analytics and you can opt out of their cookies by clicking on the following link&nbsp;<a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer noopener">https://tools.google.com/dlpage/gaoptout</a>.</p>
    <h4>HOW TO MANAGE COOKIES</h4>
    <p>Please remember that cookies are often used to enable and improve certain functions on a website. If you choose to switch certain cookies off, it is likely to affect how websites work. If you block cookies completely, many websites will not work properly and some functionality on these websites will not work at all. This is very important because any settings that you change in your browser will not just affect XPG factor sp. z o.o. cookies, but will also apply to other websites that you visit (unless you choose to block cookies from particular sites).</p>
    <p>Most modern browsers will allow you to:</p>
    <ul>
        <li>see the cookies that have been set and delete them on an individual basis</li>
        <li>block third-party cookies</li>
        <li>block cookies from particular sites</li>
        <li>block all cookies from being set</li>
        <li>delete all cookies when you close your browser</li>
    </ul>
    <p>If you wish to amend your cookie preferences for this website, or any other websites, you can do this through your browser settings. Your browser’s ‘help’ function will tell you how to do this.</p>
    <h4 class="wp-block-heading">LINKS TO OTHER WEBSITES</h4>
    <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
    <h4>DISCLAIMER</h4>
    <p>The information contained in this website is for general information purposes only. The information is provided by XPG factor sp. z o.o. and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
    <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
    <p>Through this website you are able to link to other websites which are not under the control of XPG factor sp. z o.o. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Every effort is made to keep the website up and running smoothly. However, XPG factor sp. z o.o. takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
    `
];
