import React from 'react';
import classes from './SectionContent.module.scss';

interface Props {
    content: string;
}

export const SectionContent = ({content}: Props) => {

    const theObjContent = {__html:content};

  return (
    <section className={classes.section}>
        <div className={'container'}>
            <div className={classes.content} dangerouslySetInnerHTML={theObjContent} />
        </div>
    </section>
  );
};
