import { PAGES } from './pages';

export const MAIN_MENU = [
    {
        id: 1,
        name: 'Home',
        link: PAGES.HOME
    },
    {
        id: 2,
        name: 'Case study',
        link: PAGES.CASE_STUDY
    }
]
