import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScrollSectionState {
    scrollSection: boolean;
}


export enum scrolls {
  scroll = "scroll"
}

const initialState: ScrollSectionState = {
    scrollSection: false,
};

export const scrollSlice = createSlice({
    name: 'scrolls',
    initialState,
    reducers: {
        scroll: (state, action: PayloadAction<string>) => {
            state[action.payload as keyof typeof initialState] = true;
        },
        reset: (state, action: PayloadAction<string>) => {
            state[action.payload as keyof typeof initialState] = false;
        },
    },
});

export const { scroll, reset } = scrollSlice.actions;

export default scrollSlice.reducer;
