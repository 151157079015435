import React from 'react';
import classes from './NotFoundPage.module.scss';
import { ButtonPrimary } from '../../components/ButtonPrimary';
import { PAGES } from '../../constants';

export const NotFoundPage = () => {

  return (
    <div className={classes.section}>
      <div className={classes.inner}>
          <div className="container">
              <div className={classes.head}>
                  <h1 className={classes.title}>Something went wrong</h1>
                  <div className={classes.content}>
                      <p>You can came back to the main page and try again</p>
                  </div>
              </div>
              <div className={classes.footer}>
                  <ButtonPrimary type='link' link={PAGES.HOME} text='Home page' />
              </div>
          </div>
      </div>
      <div className={classes.image}></div>
  </div>
  );
};
