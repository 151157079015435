import React from 'react';
import { Link } from "react-router-dom";
import classes from './Links.module.scss';
import { PAGES } from '../../constants';

export const Links = () => {

  return (
    <ul className={classes.links}>
        <li className={classes.line}>
            <span className={classes.title}>Contact information</span>
        </li>
        <li className={classes.line}>
            <a href="tel:+48883632349">+48 883 632 349</a>
        </li>
        <li className={classes.line}>
            <a href="mailto:contact@xpgfactor.com">contact@xpgfactor.com</a>
        </li>
    </ul>
  );
};
