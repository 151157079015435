import React, { useEffect, useState } from 'react';
import classes from './WelcomeDafault.module.scss';

interface Props {
    title: string;
    description?: string;
    image: string;
    shadow?: boolean;
  }

export const WelcomeDefault = ({title, description, image, shadow = true}:Props) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    },[]);

  return (
    <section className={`${classes.welcome} ${show ? classes.show : ''}`}>
        <div className={classes.inner}>
            <div className={'container'}>
                <div className={classes.panel}>
                  <h1 className={classes.title}>{title}</h1>
                  {description ? (<div className={classes.description}>{description}</div>) : null}
                </div>
            </div>
        </div>
        <div className={`${classes.media} ${shadow ? classes.shadow : ''}`}>
            <div className={classes.image} style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    </section>
  );
};
