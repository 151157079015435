import React, { ReactNode } from 'react';
import classes from './SectionDefault.module.scss';

interface Props {
    type?: 'primary';
    children: ReactNode;
    title?: string;
    description?: string;
    unic?: string;
}

export const SectionDefault = ({type, children, title, description, unic}: Props) => {

  return (
    <section className={`${classes.section} ${type ? classes.primary : ''}`} id={unic ? unic : ''}>
        <div className={'container'}>
            { title || description ? (<div className={classes.head}>
                {title && <h2 className={classes.title}>{title}</h2>}
                {description && <div className={classes.description}>{description}</div>}
            </div>) :null }
            <div className={classes.body}>
                {children}
            </div>
        </div>
    </section>
  );
};
