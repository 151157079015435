import React, { ReactNode } from 'react';
import classes from './Technologies.module.scss';

interface Props {
    items: any;
}

export const Technologies = ({items}: Props) => {

  return (
    <div className={classes.grid}>
        {
            items.map((tech: any, index: number) => {
                return (
                    <div className={classes.cell} key={index}>
                        <div className={classes.technology}>
                            <strong className={classes.title}>{tech.title}</strong>
                            <ul className={classes.list}>
                                {
                                    tech.list.map((item: any, index: number) => {
                                        return (
                                            <li key={index} className={classes.line}>
                                                <div className={classes.box}>
                                                    <div className={classes.icon} style={{ backgroundImage: `url(${item.icon})` }} />
                                                    <div className={classes.text}>{item.title}</div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                )
            })
        }
    </div>
  );
};
